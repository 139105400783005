import React from 'react'
import './styleAll.css';
export default function MapBtn() {
  return (
    <div className="map">
      <div>
        <img width="30" src="./favicon/map.png" alt="" />
        <span >
          Sayt xaritasi
        </span>
      </div>
    </div>
  );
}
