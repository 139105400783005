import React from "react";
import style from "../../css/newsCard.module.scss";
import president from "../../images/president.jpg";
import { Link } from "react-router-dom";

export default function RightContent() {
  return (
    <>
      <div className={style.right_content}>
        <div className={style.image}>
          <img src={president} alt="Year announcement" />
        </div>
        <h3>2023 yil - "Insonga e'tibor va sifatli ta'lim yili"</h3>
        <Link to={'/contact-us'}>
          <div className={style.contact}>
            <p>Boshqaruv raisining virtual qabul xonasi</p>
          </div>
        </Link>
      </div>
    </>
  );
}
