import React from 'react';
import '../../css/document.scss';
import { Files } from '../Files';
import Notify from 'simple-notify';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function DocumentList() {
  let param = useParams()
  let title = param.title
  const [fileData, setFileData] = React.useState([])
  const [docs, setDocs] = React.useState([])
  const [images, setImages] = React.useState([])
  const [docTitle, setDocTitle] = React.useState('')
  
  React.useEffect(() => {
    const getFileData = async () => {
      let category = await axios.get(
        'https://api-surxon.surxonpi.uz/api/v1/document-categories/?format=json'
      );
      let index = category.data.find((e) => e.slug === title).id;
      let blockTitle = category.data.find((e) => e.slug === title).title;
      setDocTitle(blockTitle)
      let allDocs = await axios.get(
        'https://api-surxon.surxonpi.uz/api/v1/documents/?format=json'
      );
      const filteredData = allDocs.data.filter(
        (e) => e.document_category === index
      );
      const data = filteredData.filter((e) =>
        /.(txt)$/.test(e.file.toLowerCase())
      );
      const updatedFileData = await Promise.all(
        data.map(async (fileObject) => {
          try {
            const response = await axios.get(fileObject.file);
            return {
              url: `https://www.youtube.com/embed/${
                response.data.split('https://www.youtube.com/watch?v=')[1]
              }`,
            };
          } catch (error) {
            console.error(error);
          }
        })
      );
      setFileData(updatedFileData);
      const docFiles = filteredData.filter((e) =>
        /.(doc|docx|pdf|xls|xlsx|ppt|pptx)$/.test(e.file.toLowerCase())
      );
      const imageFiles = filteredData.filter((e) =>
        /.(jpe?g|png|webp)$/.test(e.file.toLowerCase())
      );
      setDocs(docFiles);
      setImages(imageFiles);
    };

    getFileData();
  }, [title]);

  const checkTypeOfFile = (file) => {
    let fullFile = file.toLowerCase().split('.');
    let fileType = fullFile[fullFile.length - 1];
    return fileType;
  };
 
  const openFile = (fileType, fileUrl) => {
    if (
      fileType === 'doc' ||
      fileType === 'docx' ||
      fileType === 'ppt' ||
      fileType === 'pptx' ||
      fileType === 'xls' ||
      fileType === 'xlsx'
    ) {
      new Notify({
        status: 'warning',
        title: 'Ogohlantirish',
        text: "Bu fayl brauzer tomonidan qo'llab quvvatlanmaydi.",
        effect: 'slide',
        speed: 300,
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: 2000,
        gap: 20,
        distance: 20,
        type: 1,
        position: 'right top',
      });
    } else {
      window.open(fileUrl, '_blank');
    }
  };



  return (
    <div className="document">
      <h2>{docTitle}</h2>
      <div className="docs">
        <ul>
          {docs.length !== 0 || docs !== undefined
            ? docs.map((item) => (
                <>
                  <li key={item.title_uz}>
                    <img
                      src={
                        Files.find((e) => e.type === checkTypeOfFile(item.file))
                          .icon
                      }
                      alt=""
                      className="icon"
                    />
                    <div
                      className="fileName"
                      onClick={() =>
                        openFile(checkTypeOfFile(item.file), item.file)
                      }
                    >
                      <span>{item.title_uz}</span>
                    </div>
                    <a href={`${item.file}`} className="download" download>
                      <i className="fa fa-download"></i>
                    </a>
                  </li>
                </>
              ))
            : ''}
          {images.length !== 0 || images !== undefined
            ? images.map((item) => (
                <div className="imageFile" key={item.title_uz}>
                  <div className="top">
                    <h3>{item.title_uz}</h3>
                    <a href={item.file} download>
                      <i className="fa fa-download"></i>
                    </a>
                  </div>
                  <a href={item.title_uz} target={'_blank'} rel={'noreferrer'}>
                    <img src={item.file} alt={item.title_uz} />
                  </a>
                </div>
              ))
            : ''}
          {fileData.map((item) => (
            <div className="youtube-video" key={item.url}>
              <div className="top-title">
                <a href={item.url}>
                  <i className="fa-brands fa-youtube"></i>
                </a>
                <a href={item.url}>
                  <i className="fa-regular fa-arrow-up-right-from-square"></i>
                </a>
              </div>
              <iframe
                height={430}
                src={item.url}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}
