import React from 'react';
import Navigation from './Navigation';
import { Outlet } from 'react-router-dom';
import Weather from './Weather';
import CalAndPoll from './CalAndPoll';
import Footer from './Footer';
import MediaSect from './Videos/MediaSect';

const Layout = () => {
  return (
    <>
      <Navigation />
      <Outlet />
      <MediaSect />
      <CalAndPoll />
      <Weather />
      <Footer />
    </>
  );
};

export default Layout;
