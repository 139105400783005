import React from 'react'
import './styleAll.css';
import GlassFont from './GlassFont';


export default function Glasses() {

  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className="glasses"> 
      <div 
      onClick={() => setIsOpen(!isOpen)}
      className="glasses_main"
      >
        <img width="30" src="./favicon/glasses.png" alt="" />
        <span>
          zaif ko'ruvchilar uchun
        </span>
      </div>
      <div 
      style={isOpen ? {transform:"translateY(0px)"}: {transform:"translateY(-400px)"}}
      className="pop_glasses"
      >
        <GlassFont/>
      </div>
    </div>
  );
}
