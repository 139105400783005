import React from 'react'
import './styleAll.css';
import { LangMenu } from '../../context';


export default function Language() {


const { setChangLang } = React.useContext(LangMenu);

  return (
    <>
      <div className="language">
        <span onClick={() => setChangLang('ru_nav')}>
          <img src="./favicon/russia.png" alt="" />
          Rus
        </span>
        <span onClick={() => setChangLang('uz_nav')}>
          <img src="./favicon/uzbekistan.png" alt="" />
          Uzb
        </span>
      </div>
    </>
  );
}
