import React from 'react'
import style from '../css/main.module.scss'
import logo from '../images/logo__.png'
import call_man from '../images/support_man.png'
import { Link } from 'react-router-dom'
import Menu from './Menu'
import GlassFont from './Special/GlassFont'
import Special from './Special'

export default function Navigation() {

 
    

    return (
      <>
        <nav>
          {/* <motion.div className={style.navbar_top} initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transiton={{ duration: 5, ease: 'linear' }}>
                    <div className={style.container}>
                        <ul>
                            <li>
                                <a href="/">
                                    <div className={style.icon}>
                                        <i className="fa fa-home"></i>
                                    </div>
                                    <span>Бош саҳифа</span>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <div className={style.icon}>
                                        <i className="fas fa-sitemap"></i>
                                    </div>
                                    <span>Сайт харитаси</span>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <div className={style.icon}>
                                        <i className="fa fa-mobile-screen-button"></i>
                                    </div>
                                    <span>Мобил талқин</span>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <div className={style.icon}>
                                        <i className="fa fa-eye"></i>
                                    </div>
                                    <span>Заиф кўрувчилар учун</span>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <div className={style.icon}>
                                        <i className="fa fa-sign-in"></i>
                                    </div>
                                    <span>Тизимга кириш</span>
                                </a>
                            </li>
                        </ul>
                        <div className={style.second_part}>
                            <div className={style.search} security="ri990103" ref={input}>
                                <input type="text" placeholder='Сайт бўйлаб излаш' onFocus={() => shrink()} onBlur={() => { defaultCont() }} />
                                <i className="fa fa-search"></i>
                            </div>
                            <div className={style.languages}>
                                <div className={style.language}>

                                </div>
                                <div className={style.language}>

                                </div>
                                <div className={style.language}>

                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div> */}
          <Special/>
          <div
            style={{
              width: '100%',
              padding: '20px 40px',
              background: 'green',
              display: 'flex',
              alignItems: 'center',
            
            }}
          >

            <Menu />
          </div>
          <div style={{ marginTop: '140px' }} className={style.nav_contact}>
            <div className={style.container}>
              <div className={style.contact}>
                <Link to="/">
                  <div className={style.logo}>
                    <img src={logo} alt="logo" />
                  </div>
                </Link>
                <div className={style.call}>
                  <h2 className={style.comp_name}>
                    "Surxon Parmalash Ishlari" aksiyadorlik jamiyati
                    <div className={style.call_number}>
                      <div className={style.call_title}>
                        <img src={call_man} alt="" />
                        <span>ishonch telefonlari:</span>
                      </div>
                      <div className={style.numbers}>
                        <span>(8-376)46-53342</span>
                        <br />
                      </div>
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
}
