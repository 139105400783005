import React from 'react';
import MapBtn from './Special/MapBtn';
import Glasses from './Special/Glasses';
import Language from './Special/Language';
import Search from './Special/Search';
import {Link} from 'react-router-dom'
import './Special/styleAll.css'


export default function Special() {
  return (
    <div
     
      style={{
        background: '#000',
        padding: '10px',
      }}
    >
      <div className="container top_menu" >
        <Link to={'/'}>
          <div className="head_main">
            <img width="30" src="./favicon/home.png" alt="" />
            <span style={{ display: 'block', marginLeft: '20px' }}>
              Bosh sahifa
            </span>
          </div>
        </Link>
        <Link to={"map_site"}> <MapBtn /> </Link>
        <Glasses />
        <Language />
        <Search />
      </div>
    </div>
  );
}
