import slide_1 from '../images/5.JPG'
import slide_2 from '../images/2.JPG'
import slide_3 from '../images/3.JPG'
import slide_4 from '../images/4.JPG'
import slide_5 from '../images/1.JPG'
import slide_6 from '../images/6.JPG'

export const slides = [
    {
        "slide":slide_1
    },
    {
        "slide":slide_2
    },
    {
        "slide":slide_3
    },
    {
        "slide":slide_4
    },
    {
        "slide":slide_5
    },
    {
        "slide":slide_6
    },
]