import React from 'react';
import './styleAll.css';
import axios from 'axios';


export default function Search() {

  const [isOpenSearch, setIsOpenSearch] = React.useState(false)
  
  const [document, setDocument] = React.useState([])
  const [value, setValue] = React.useState('')



  React.useEffect(() =>{
      async function searchRes(){
        const data = await axios(
          `https://api-surxon.surxonpi.uz/api/v1/documents/?format=json`
        );
        setDocument(data.data)
      }

      searchRes()
  },[])



  

 
        
  const filterDocument = document.filter((item) => {
    return item.title.toLowerCase().includes(value.toLowerCase())
  });
       
      
 
  

  return (
    <>
      <div onClick={() => setIsOpenSearch(true)} className="search">
        <img width="20" src="./favicon/search.png" alt="" />
      </div>

      <div
        style={
          isOpenSearch
            ? { transform: 'translateX(0%)' }
            : { transform: 'translateX(120%)' }
        }
        className="search_pop"
      >
        <div className="search_results">
          <div className="search_panel">
            <input
              value={value}
              onInput={(e) => setValue(e.target.value)}
              placeholder="Qiduruv"
            />
            
            <button onClick={() => setIsOpenSearch(false)} className="close">
              <img src="./favicon/cross.png" alt="" />
            </button>
          </div>

          <div className="filter_result">
            <ul>
              {filterDocument.map((item) => {
                return (
                  <a href={`${item.file}`} className="download" download>
                    {item.title}
                    <i className="fa fa-download"></i>
                  </a>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
