import React from 'react';

export default function GlassFont() {
  const [range, setRange] = React.useState(16);
  const [isTouch, setIsTouch] = React.useState(false);

  React.useEffect(() => {
    function fontSiZe() {
      let size = document.querySelectorAll('*');
      size.forEach((item) => {
        item.style.fontSize = `${range}px`;
      });
    }
    isTouch ? fontSiZe() : setIsTouch(false);
  }, [range, isTouch]);

  return (
    <div
      style={{
        padding: '10px',
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
        <p>A A</p>
      <input
        style={{
          marginLeft: '20px',
        }}
        id="range"
        type="range"
        onClick={() => setIsTouch(true)}
        onChange={(e) => setRange(e.target.value)}
        value={range}
        min="16"
        max="30"
        step="1"
      />
    </div>
  );
}
