import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout';
import DocumentList from './Components/pages/DocumentList'
import './css/App.css';
import Docs from './Components/Docs';
import SliderBlock from './Components/SliderBlock';

import Contact from './Components/Contact';
import MapSite from './Components/Special/MapSite';

//  function speak(text) {
//    console.log(text);
//    const message = new SpeechSynthesisUtterance();
//    message.lang = 'ru-RU';
//    message.text = text;
//    window.speechSynthesis.speak(message);
//  }






function App() {
  
  return (
    <>
      <div className="All">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<SliderBlock />} />
            <Route path="docs" element={<Docs />}>
              <Route path="/docs/:title" element={<DocumentList />} />
            </Route>

            <Route path="contact-us" element={<Contact />} />
            <Route path="/map_site" element={<MapSite />} />
          </Route>
        </Routes>
      </div>

      
    </>
  );
}

export default App;
