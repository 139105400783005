import React from 'react';
import './styleAll.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LangMenu } from '../../context';

export default function MapSite() {
  const {changeLang} = React.useContext(LangMenu)
  
  const [linkMenu, setLinkMenu] = React.useState([]);

  React.useEffect(() => {
    async function getMenuMap() {
      let data = await axios(
        `https://api.npoint.io/26f46edbf4c397c1e612/${changeLang}`
      );

      setLinkMenu(data.data);
    }
    getMenuMap();
  }, [changeLang]);

  console.log(linkMenu);

  return (
    <div className="container">
      <div className="map-site">
        <Link to={`/`} className="title">
          Bosh sahifa
        </Link>
        {linkMenu.map((item) => {
          return (
            <nav key={item.title}>
              {item.title}
              <ul>
                {item.routes.map((routes, index) => {
                  return (
                    <li key={routes.route_title}>
                      <Link to={`${routes.path}`}>{routes.route_title}</Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          );
        })}
      </div>
    </div>
  );
}
