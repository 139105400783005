import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './css/index.css';
import App from './App';
import { LangMenu } from './context';

window.addEventListener('click', () => {
  var synth = window.speechSynthesis;
  var utterance = new SpeechSynthesisUtterance(
    window.getSelection().toString()
  );
  utterance.lang = 'ru-RU';
  utterance.rate = 0.7;

  synth.speak(utterance);
});

const root = ReactDOM.createRoot(document.getElementById('root'));

export default function Apps() {
  const [changeLang, setChangLang] = React.useState('uz_nav');
  return (
    <>
      <BrowserRouter>
        <LangMenu.Provider value={{ changeLang, setChangLang }}>
          <App />
        </LangMenu.Provider>
      </BrowserRouter>
    </>
  );
}

root.render(<Apps />);
